import { Link } from "react-router-dom";

const formatCurrency = (x) => {
    x = Math.floor(x);
    x = x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
    return x;
}

const DeviceCell = ({ device, cellSize }) => {
    return (<div className="game_cell has_cover" style={{width: cellSize.cell.width}}>
        <Link className="thumb_link game_link" to={`/device/${device?.uuid}` ?? "#"}>
            <div className="game_thumb" style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/${device?.cover_image})`, width: cellSize.image.width, height: cellSize.image.height, backgroundSize: "contain", backgroundRepeat:"no-repeat" }}  >
            </div>
        </Link>
        <div className="game_cell_data">
            <div className="game_title">
                <Link to={`/device/${device?.uuid}` ?? "#"} className="title game_link">
                    {device?.name}
                </Link>
            </div>
            <div className="game_author">
                {formatCurrency(device?.rental_price)}
            </div>
            <div className="game_genre">
                {device?.categories?.map(item => item.name).join(", ")}
            </div>
            <div className="game_text"> 
                {device?.short_description}
            </div>
        </div>
    </div>
    );
}

export default DeviceCell;