import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

const FilterGroupWidget = ({ name, groupName, selectedItem, items, handleChangeOption }) => {
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedItem) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }
    }, [selectedItem]);

    const handleClick = (item) => {
        handleChangeOption({ [name]: item?.name });
    }

    const handleClearFilter = () => {
        handleChangeOption({ [name]: "" });
    }

    return (
        <div className={`browse_filter_group_widget ${selectedItem ? "active" : ""} ${isOpen ? "filters_open" : ""}`}>
            <div className="filter_group_label">
                <button className="group_toggle_btn" onClick={()=>setIsOpen(!isOpen)}>
                    <span className="filter_arrow">
                        {isOpen ? (<i className="fa-solid fa-caret-down"></i>) : (<i className="fa-solid fa-caret-right"></i>)}
                    </span>
                    {groupName}
                </button>
                {
                    selectedItem && (
                        <button type="button" className="filter_clear" onClick={handleClearFilter}>
                            <i className="fa-solid fa-circle-xmark"></i>
                        </button>
                    )
                }

            </div>
            <ul>
                {items?.map((item) => (
                    <li onClick={() => { handleClick(item) }} key={item?.uuid} className={`${item?.name === selectedItem ? "active" : ""}`}  ><Link to="#"><span className="" style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{marginRight:"5px", fontSize:"15px", display:"inline-flex", alignItems:"center"}} className={`${item.icon_code}`}></span>
                        </span>{item?.name}</Link>
                    </li>
                ))}
            </ul>
            {
                selectedItem && (
                    <button className="toggle_more_btn" onClick={() => { setIsOpen(!isOpen) }}>{isOpen ? t("FILTER-GROUPS-WIDGETS-Fewer") : t("FILTER-GROUPS-WIDGETS-More")}</button>
                )}
        </div>
    );
}

export default FilterGroupWidget;