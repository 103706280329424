import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../slice/userAuthSlice";

const Logout = () => {
  const dispatch = useDispatch();
  const natigate = useNavigate();
  useEffect(() => {
    dispatch(signOut());
    natigate("/");
  }, [natigate,dispatch]);

  return <div />;
};

export default Logout;