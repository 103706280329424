import apiCaller from "./config";

export const updateInfo = async (data) => {
    const path = `/users/update-info`;
    const result = await apiCaller("PUT", path, data );
    return result;
}

export const changePassword = async (data) => {
    const path = `/users/change-password`;
    const result = await apiCaller("PUT", path, data );
    return result;
}

export const changeEmail = async (data) => {
    const path = `/users/change-email`;
    const result = await apiCaller("PUT", path, data );
    return result;
}

export const deleteAccount = async (data) => {
    const path = `/users/delete`;
    const result = await apiCaller("DELETE", path, data );
    return result;
}
