import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { updateInfo } from "../../../slice/userSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"

const Profile = () => {
    const [data, setDatta] = useState({
        fullname:''
    });
    const dispatch = useDispatch();
    const currentFullname = useSelector((state) => state?.userAuth?.user?.fullname);
    const { t }= useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateInfo(data, (data)=>{
            window.flash(data.message, 'success')
        },(error)=>{
            window.flash(error.error, 'success')
        }))
    }
    
    const handleChange = (e) => {
        setDatta({
            ...data,
            [e.target.name]: e.target.value !== undefined ? e.target.value : ""
        });
    }

    useEffect(()=>{
        if (currentFullname!==undefined){
            setDatta((prevData) => ({
                ...prevData,
                fullname: currentFullname
            }))
        }
    },[currentFullname])
    
    return (
        <>
            <h2>{t("SETTINGS-Profile-title")}</h2>
            <form className="form" onSubmit={handleSubmit}>
                <div className="input_row" > 
                    <label>
                        <div className="label">
                            {t("SETTINGS-Profile-name")}
                            <span className="sub">
                                {t("SETTINGS-Profile-des")}
                            </span>
                        </div>
                        <input type="text" value={data.fullname} name="fullname" onChange={handleChange} />
                    </label>
                </div>
                <div className="buttons">
                    <button className="button">{t("SETTINGS-Profile-button")}</button>
                </div>
            </form>
        </>
    );
}

export default Profile;