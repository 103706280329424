import { useState } from "react";
import { useDispatch } from "react-redux"
import { changeEmail } from "../../../slice/userSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"

const ChangeEmail = () => {
    const [data, setDatta] = useState({
        email:'',
        currentPassword: ''
    });
    const dispatch = useDispatch();
    const currentEmail = useSelector((state) => state?.userAuth?.user?.email);
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changeEmail(data, (data)=>{
            window.flash(data.message, 'success')
        },(error)=>{
            window.flash(error.message, 'success')
        }))
    }
    
    const handleChange = (e) => {
        setDatta({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    
    return (
        <>
            <h2>{t("SETTINGS-Email-title")}</h2>
            <p>{t("SETTINGS-Email-des")}<strong>{currentEmail}</strong></p>
            <form className="form" onSubmit={handleSubmit}>
                <div className="input_row" > 
                    <label>
                        <div className="label">
                            {t("SETTINGS-Email-new-email")}
                        </div>
                        <input type="email" value={data.email} name="email" onChange={handleChange} />
                    </label>
                </div>
                <div className="input_row" > 
                    <label>
                        <div className="label">
                            {t("SETTINGS-Email-Cur-password")}
                        </div>
                        <input type="password" value={data.currentPassword} name="currentPassword" onChange={handleChange} />
                    </label>
                </div>
                <div className="buttons">
                    <button className="button">{t("SETTINGS-Email-button")}</button>
                </div>
            </form>
        </>
    );
}

export default ChangeEmail;