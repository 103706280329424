import {
    getAllSearchResult as getAllSearchResultApi
} from "../apis/search";

export const getAllSearchResult =
    (data, resolve, reject) => async () => {
        try {
            const res = await getAllSearchResultApi(data);
            if (res.success) {
                resolve(res.data);
            } else {
                reject(res);
            }
        } catch (error) {
            reject(error);
        }
    };
