import apiCaller from "./config";

export const getDetailGame = async (gameId) => {
    const path = `/games/${gameId}`;
    const result = await apiCaller("GET", path, {});
    return result;
}

export const getAllSettings = async (data) => {
    const path = "/settings";
    const result = await apiCaller("GET", path, data);
    return result;
}

export const getAllGames = async (data) => {
    const path = "/games";
    const result = await apiCaller("GET", path, null, data);
    return result;
}

export const getAllBanner = async (data) => {
    const path = "/settings/banners";
    const result = await apiCaller("GET", path, {});
    return result;
}

export const getAllBannerForMobileView = async (data) => {
    const path = "/settings/banners-mobile";
    const result = await apiCaller("GET", path, {});
    return result;
}
