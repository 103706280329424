import { useTranslation } from "react-i18next";
import "./style.css";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="footer">
            <div className="primary">
                <div className="social">
                    <a href="https://www.facebook.com/stoovn/">
                        <i className="fa-brands fa-facebook-square"></i>
                    </a>
                </div>
                <a href="https://stoo.vn">{t("FOOTER-About")}</a>
                <a href="https://stoo.vn">{t("FOOTER-FAQ")}</a>
                <a href="https://stoo.vn">{t("FOOTER-Blog")}</a>
                <a href="https://stoo.vn/contact">{t("FOOTER-Contact")}</a>
            </div>
            <div className="secondary">
                <span className="copyright">{t("FOOTER-Copyright")}{new Date().getFullYear()}{t("FOOTER-Company")}</span>
            </div>
        </div>
    );
}


export default Footer;