import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../../utils";

import "./style.css";

const LanguageWidget = () => {
    const ref = useRef()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { i18n } = useTranslation();
  
    useEffect(() => {
      const checkIfClickedOutside = e => {
        if (isMenuOpen && ref?.current && !ref?.current?.contains(e.target)) {
          setIsMenuOpen(false)
        }
      }
  
      document.addEventListener("mousedown", checkIfClickedOutside)
  
      return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside)
      }
    }, [isMenuOpen])
  
    const handleSelectOption = (languageValue) => {
      setIsMenuOpen(false);
      setLanguage(languageValue);
      i18n.changeLanguage(languageValue);
    }
  
    return (
      <div className="language_container" ref={ref}>
        <div className={`${isMenuOpen?"open popup_visible":""}`}>
          <button className="filter_value" onClick={() => setIsMenuOpen(oldState => !oldState)}>
            <span className="value_label">
                <span>{i18n.language==="vi"?"🇻🇳":"🇬🇧"}</span>
            </span>
            <i className="fa-solid fa-chevron-down svgicon language_icon"></i>
          </button>
          <div className="filter_popup">
            <ul className="filter_options">
              <li onClick={()=> handleSelectOption('vi')} className="filter_option">🇻🇳</li>
              <li onClick={()=> handleSelectOption('en')} className="filter_option">🇬🇧</li>
            </ul>
          </div>
        </div>
      </div>
    )
}


export default LanguageWidget;