import { useState } from 'react';
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../../slice/userAuthSlice";
import { useTranslation } from "react-i18next";

import "./style.css";

const initialState = {
    username: '',
    password: '',
    rePassword: '',
    email: '',
    fullname: '',
    gamer_account: false,
    developer_account: false,
    digest_signup: false,
    accept_terms: false
}

const Register = () => {
    const dispatch = useDispatch();
    const [registerData, setRegisterData] = useState(initialState);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(signUp(registerData, ()=>{
            navigate("/");
        }, (errors)=>{
            if (typeof errors !== 'array'){
                errors = [errors]
            }

            setErrors(errors);
        })) 
    }

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setRegisterData({ ...registerData, [name]: value });
    }

    const renderRegisterForm = () => (
        <form className="form" onSubmit={handleSubmit}>
            <InputRow>
                <Label>{t("REGISTER-Username")}</Label>
                <Input name="username" autoFocus required type="text" value={registerData.username} onChange={handleChange}/>
            </InputRow>
            <InputRow>
                <Label>{t("REGISTER-Password")}</Label>
                <Input name="password" required type="password" onChange={handleChange} value={registerData.password} />
            </InputRow>
            <InputRow>
                <Label>{t("REGISTER-Repassword")}</Label>
                <Input name="rePassword" required type="password" onChange={handleChange} value={registerData.rePassword} />
            </InputRow>
            <InputRow>
                <Label>{t("REGISTER-Email")}</Label>
                <Input required name="email" placeholder={t("REGISTER-Email_placeholder")} type="email" onChange={handleChange} value={registerData.email} />
            </InputRow>
            <InputRow>
                <Label>{t("REGISTER-Fullname")}</Label>
                <Input required name="fullname" type="text" onChange={handleChange} value={registerData.fullname} />
            </InputRow>
            {/* <div className="user_configurator">
                <strong>About you</strong>
                <InputRow>
                    <Input value="yes" name="gamer_account" checked={registerData.gamer_account} type="checkbox" onChange={handleChange} />
                    {"I'm interested in playing or downloading games on itch.io"}
                </InputRow>
                <InputRow>
                    <Input value="yes" name="developer_account" checked={registerData.developer_account} type="checkbox" onChange={handleChange} />
                    {"I'm interested in ...."}
                </InputRow>
                <p>
                    {"You can change your responses to these questions later, they are used to hint itch.io in how it should present itself to you."}
                </p>
            </div> */}
            {/* <InputRow>
                <Input value="yes" name="digest_signup" checked={registerData.digest_signup} type="checkbox" onChange={handleChange} />
                {"Sign me up for the bi-monthly itch.io digest newsletter"}
            </InputRow> */}
            <InputRow>
                <Input value="yes" name="accept_terms" type="checkbox" checked={registerData.accept_terms} onChange={handleChange} />
                {t("REGISTER-Accept_terms")}
                <Link to="#">{t("REGISTER-terms")}</Link>
            </InputRow>
            <div className="buttons">
                <button className="button">{t("REGISTER-create")}</button>
                <span className="line">
                    {t("REGISTER-line")}
                    <Link to="/login">{t("REGISTER-login")}</Link>
                </span>
            </div>
        </form>
    );

    return (
        <>
            <Header />
            <div className="main">
                <div className="inner_column">
                    <div className="user_register_page page_widget">
                        <div className="register_header">
                            <h2>{t("REGISTER-Title")}</h2>
                        </div>
                        <div className="register_form_widget">
                            <div className="register_columns">
                                <div className="form_column">
                                    <Errors errors={errors}/>
                                    {renderRegisterForm()}
                                </div>
                                <AboutColumn />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

const AboutColumn = () => {
    const { t } = useTranslation();

    return (
        <div className="about_column">
            <h2>{t("REGISTER-Who")}</h2>
            <p>
                <strong>{t("REGISTER-website")}</strong> 
                {t("REGISTER-Who-answer")}
            </p>
            <h3>{t("REGISTER-answer1")}</h3>
            <p>{t("REGISTER-answer1_des_1")}<strong>{t("REGISTER-website")}</strong>{t("REGISTER-answer1_des_2")}</p>
            <h3>{t("REGISTER-answer2")}</h3>
            <p>{t("REGISTER-answer2_des_1")}<strong>{t("REGISTER-website")}</strong>{t("REGISTER-answer2_des_2")}<strong>{t("REGISTER-website")}</strong>.</p>
        </div>
    )
}

const InputRow = ({ children }) => (
    <div className="input_row">
        <label>
            {children}
        </label>
    </div>
)

const Label = ({ children }) => (
    <div className="label">
        {children}
    </div>
)

const Input = ({ children, ...props }) => (
    <input className="text_input" {...props} />
);

const Errors = ({ errors }) => {
    const { t } = useTranslation();

    return errors?.length > 0 && (<div className="form_errors">
        <p>
            <strong>{t("REGISTER-Error")}</strong>
        </p>
        <ul>
            {errors.map((error, index) => <li  key={`Error-${index}`}>{error}</li>)}
        </ul>
    </div>)
}

export default Register;