import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { fetchSessionStorage } from "./slice/userAuthSlice";
import { useDispatch } from 'react-redux';
import { initLanguage, getLanguage } from "./utils";
import { useTranslation } from "react-i18next";

import Homepage from "./pages/Homepge";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Detail from "./pages/Detail";
import Search from "./pages/Search";
import Logout from "./pages/Logout";
import Settings from "./pages/Settings";
import Profile from "./pages/Settings/Profile";
import Password from "./pages/Settings/Password";
import Email from "./pages/Settings/Email";
import Flash from './components/Flash';
import Bus from "./utils/Bus";
import DeleteAccount from "./pages/Settings/Delete";
import PrivateRoute from "./components/PrivateRoute";
import Devices from "./pages/Devices";
import DetailDevice from "./pages/DetailDevice";

import "./App.css";
import "./i18n";

const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));

  useEffect(() => {
    dispatch(fetchSessionStorage());
    const currentLang = getLanguage();
    if (!currentLang) {
      initLanguage("vi");
    } else {
      if (i18n.language !== currentLang) {
        i18n.changeLanguage(currentLang);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="main_layout responsive">
      <Flash />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/game/:gameId" element={<Detail />} />
          <Route path="/search" element={<Search />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} >
            <Route index element={<Profile />} />
            <Route path="password" element={<Password />} />
            <Route path="email-addresses" element={<Email />} />
            <Route path="delete-account" element={<DeleteAccount />} />
          </Route>
          <Route path="/devices" element={<Devices />} />
          <Route path="/device/:deviceId" element={<DetailDevice />} />
          <Route path="/" index element={<Homepage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
