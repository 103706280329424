import {
    getDetailDevice as getDetailDeviceApi,
    getAllDevices as getAllDevicesApi,
    getAllCategory as getAllCategoryApi,
} from "../apis/device";

export const getDetailDevice =
    (data, resolve, reject) => async () => {
        try {
            const res = await getDetailDeviceApi(data);
            if (res.success) {
                resolve(res.data);
            } else {
                reject(res);
            }
        } catch (error) {
            reject(error);
        }
    };

export const getAllDevices =
    (data, resolve, reject) => async () => {
        try {
            const res = await getAllDevicesApi(data);
            if (res.success) {
                resolve(res.data);
            } else {
                reject(res.message);
            }
        } catch (error) {
            reject(error);
        }
    };


export const getAllCategory =
    (data, resolve, reject) => async () => {
        try {
            const res = await getAllCategoryApi(data);
            if (res.success) {
                resolve(res.data);
            } else {
                reject(res.message);
            }
        } catch (error) {
            reject(error);
        }
    };
