import { createSlice } from "@reduxjs/toolkit";
import {
    signIn as signInApi,
    signUp as signUpApi
} from "../apis/auth";

const initialState = {
    token: null,
    user: null,
};

const userAuthSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        setAuth: (state, { payload: { token, user } }) => ({
            ...state,
            token,
            user
        }),
        clearAuth: () => {
            return ({
            ...initialState,
        })},
        setUser:(state, { payload: { user } }) => ({
            ...state,
            user: {
                ...user,
            },
        }),
    },
});

export const { setAuth, clearAuth, setUser } = userAuthSlice.actions;

export const saveSessionStorage = ({ token, user }) => {
    localStorage.setItem("userAuth", JSON.stringify({ token, user }));
};

export const signOut = () => (dispatch) => {
    localStorage.setItem("userAuth", "");
    dispatch(clearAuth());
};

export const fetchSessionStorage = () => (dispatch) => {
    try {
        const userAuth = JSON.parse(localStorage.getItem("userAuth"));
        if (userAuth) dispatch(setAuth(userAuth));
    } catch (error) { }
};

export const getSessionStorage = () => {
    try {
        const userAuth = JSON.parse(localStorage.getItem("userAuth"));
        return userAuth;
    } catch (error) { }
};

export const signIn =
    (loginData, resolve, reject) => async (dispatch, getState) => {
        try {
            const res = await signInApi(loginData);
            if (res.success) {
                dispatch(setAuth({ ...res.data }));
                saveSessionStorage(getState().userAuth);
                resolve(res);
            } else {
                reject(res.message);
            }
        } catch (error) {
            reject(error);
        }
    };

export const signUp = (data, resolve, reject) => async (dispatch, getState) =>{
    try {
        const res = await signUpApi(data);
        if (res.success) {
            dispatch(setAuth({ ...res.data }));
            saveSessionStorage(getState().userAuth);
            resolve(res);
        } else {
            reject(res.message);
        }
    } catch (error) {
        reject(error);
    }
}


export default userAuthSlice.reducer;