import apiCaller from "./config";

export const signIn = async (data) => {
    const path = "/login";
    const result = await apiCaller("POST", path, data );
    return result;
}

export const signUp = async (data) => {
    const path = "/register";
    const result = await apiCaller("POST", path, data);
    return result;
}