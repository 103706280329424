import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import { signIn } from "../../slice/userAuthSlice";
import "./style.css";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import LanguageWidget from "../../components/LanguageWidget";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(signIn(formData, () => { 
            navigate('/');
        },
            (errors) => {
                if (typeof errors !== 'array'){
                    errors = [errors]
                }
                setErrors(errors);
            }));
    };

    const renderLoginForm = () => (
        <form className="form" onSubmit={handleSubmit}>
            <div className="input_row">
                <label>
                    <div className="label">{t("LOGIN-Email")}</div>
                    <input required name="email" placeholder={t("LOGIN-Email_placeholder")} type="email" value={formData.email} onChange={handleChange} />
                </label>
            </div>
            <div className="input_row">
                <label>
                    <div className="label">{t("LOGIN-Password")}</div>
                    <input required name="password" placeholder={t("LOGIN-Password_placeholder")} type="password" value={formData.password} onChange={handleChange} />
                </label>
            </div>
            <div className="buttons">
                <button className="button">
                    {t("LOGIN-Submitbutton")}
                </button>
                <span className="line">
                    {t("LOGIN-Text-or")}
                    <Link to="/register">{t("LOGIN-Text-create")}</Link>
                    {/* {t("LOGIN-Seperator")}
                    <Link to="/forgot-password">{t("LOGIN-Forgot")}</Link> */}
                </span>
            </div>
        </form>
    )

    return (
        <>
            <LoginHeader />
            <div className="main">
                <div className="inner_column compressed_column">
                    <div className="user_login_page page_widget">
                        <div className="stat_header_widget">
                            <div className="text_container">
                                <h2>
                                    {t("LOGIN-Title")}
                                </h2>
                            </div>
                        </div>
                        <div className="padded">
                            <div className="login_form_widget">
                                <Errors errors={errors} />
                                {renderLoginForm()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}


const LoginHeader = () => (
    <div className="header_widget header_widget minimal_header" style={{display:"flex", alignItems:"center"}}>
        <div className="primary_header" style={{width:"96%"}}>
            <h1 className="title">
                <Link className="header_logo" to="/" />
            </h1>
        </div>
        <div className="lang_login_container">
                <LanguageWidget />
        </div>
    </div>
);

const Errors = ({ errors }) => {
    return errors?.length > 0 && (<div className="form_errors">
        <p>
            <strong>{t("LOGIN-Error")}</strong>
        </p>
        <ul>
            {errors.map((error,index) => <li key={`Error-login-${index}`}>{error}</li>)}
        </ul>
    </div>)
}

export default Login;