import axios from "axios";
import { getLanguage } from "../utils";
import { dispatch, getState } from "../store";
import { clearAuth,saveSessionStorage  } from "../slice/userAuthSlice";

axios.interceptors.request.use((req) => {
  const userAuth = JSON.parse(localStorage.getItem("userAuth") || "{}");
  req.params = req.params ?? {};
  
  req.headers["Content-Type"] = "application/json";

  const token = userAuth.token;
  if (token) {
    req.headers["Authorization"] = `Bearer ${token}`;
  }

  const lang = getLanguage();
  if (lang){
    req.params["lang"] = lang;
  }
  
  return req;
});

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (401 === error.response.status){
      setTimeout(()=>{
        dispatch(clearAuth());
        saveSessionStorage(getState().userAuth);
      }, 4000);
    }
    
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

const apiCaller = (method = "GET", path, data, params=null) => {
  return axios({
    method,
    url: `${process.env.REACT_APP_API_URL}${path}`,
    data,
    params
  });
};

export default apiCaller;