import {
    updateInfo as updateInfoApi,
    changePassword as changePasswordApi,
    changeEmail as changeEmailApi,
    deleteAccount as deleteAccountApi
} from "../apis/user";
import { setUser, saveSessionStorage } from "./userAuthSlice";

export const updateInfo =
    (data, resolve, reject) => async (dispatch, getState) => {
        try {
            const res = await updateInfoApi(data);
            if (res.success) {
                resolve(res);
                dispatch(setUser({ ...res.data }))
                saveSessionStorage(getState().userAuth);
            } else {
                reject(res);
            }
        } catch (error) {
            reject(error);
        }
    };


export const changePassword =
    (data, resolve, reject) => async () => {
        try {
            const res = await changePasswordApi(data);
            if (res.success) {
                resolve(res);
            } else {
                reject(res);
            }
        } catch (error) {
            reject(error);
        }
    };

export const changeEmail =
(data, resolve, reject) => async (dispatch, getState) => {
    try {
        const res = await changeEmailApi(data);
        if (res.success) {
            resolve(res);
            dispatch(setUser({ ...res.data }))
            saveSessionStorage(getState().userAuth);
        } else {
            reject(res);
        }
    } catch (error) {
        reject(error);
    }
};

export const deleteAccount =
(data, resolve, reject) => async (dispatch, getState) => {
    try {
        const res = await deleteAccountApi(data);
        if (res.success) {
            resolve(res);
        } else {
            reject(res);
        }
    } catch (error) {
        reject(error);
    }
};