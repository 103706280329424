import { Link } from "react-router-dom";

const GameCell = ({ game, cellSize }) => {
    return (<div className="game_cell has_cover" style={{width: cellSize.cell.width}}>
        <Link className="thumb_link game_link" to={`/game/${game?.uuid}` ?? "#"}>
            <div className="game_thumb" style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/${game?.cover_image})`, width: cellSize.image.width, height: cellSize.image.height, backgroundSize: "contain", backgroundRepeat:"no-repeat"  }}  >
            </div>
        </Link>
        <div className="game_cell_data">
            <div className="game_title">
                <Link to={`/game/${game?.uuid}` ?? "#"} className="title game_link">
                    {game?.name}
                </Link>
            </div>
            <div className="game_text"> 
                {game?.short_description}
            </div>
            <div className="game_author">
                {game?.author || "Admin"}
            </div>
            <div className="game_genre">
                {game?.genres?.map(item => item.name).join(", ")}
            </div>
            <div className="game_platform">
                {game?.platforms.map((item,index)=>{
                    return (<span  style={{marginRight:"5px", fontSize:"15px", display:"inline-flex", alignItems:"center"}} key={`icon-game-${game?.uuid}-${index}`} className={`${item.icon_code}`}>   </span>);
                })}
            </div>
        </div>
    </div>
    );
}

export default GameCell;