import React, { useState, useRef, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FilterGroupWidget from "../../components/FilterGroupWidget";
import { getAllCategory } from "../../slice/deviceSlice";
import { getAllDevices } from "../../slice/deviceSlice";
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce';
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useRefDimensions from "../../hooks/useRefDimensions";
import { handleCalculateSizePerCell} from "../../utils"
import 'react-slideshow-image/dist/styles.css'
import { useDispatch } from "react-redux";
import DeviceCell from "../../components/DeviceCell";
import { t } from "i18next";

const DEFAULT_LIMIT = 20;

const Devices = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ref = useRef();
  const dimensions = useRefDimensions(ref);
  const [cellSize, setCellSize] = useState({
    cell:{
      width: "300px",
      height: "250px",
    },
    image:{
      width: "300px",
      height: "250px",
    }
  });

  const [category, setCategory] = useState([]);

  const dispatch = useDispatch();
  const [filter,setFilter] = useState({
    category:'',
    sortBy:{ key: 'popular', name:""},
  });


  const fetchMoreListItems = () =>  {
    dispatch(getAllDevices({
      page: currentPage + 1,
      limit: DEFAULT_LIMIT,
      category: filter.category
    },(data)=>{
      setCurrentPage(currentPage+1);
      setDevices(prevState => [
        ...prevState,
        ...data.devices_list
      ]);
      setHasMore(data.hasNextpage)
      setIsFetching(false);
    }, ()=>{
      setIsFetching(false);
    }));    
  }
  
  const [isFetching, setIsFetching, hasMore, setHasMore] = useInfiniteScroll(fetchMoreListItems);

  const handleChangeSize = useRef(debounce(data => {
    // action change size
    setCellSize(handleCalculateSizePerCell(data));
  }, 200)).current;

  useEffect(()=>{
    handleChangeSize(dimensions);
  },[dimensions]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    setIsLoading(true);
    dispatch(getAllCategory({},(data)=>{
      setCategory(data.category);
    },(error)=>{
      console.log(error);
    }))

    dispatch(getAllDevices({
      page: currentPage,
      category: filter.category,
      limit: DEFAULT_LIMIT,
    },(data)=>{
      setDevices(data.devices_list);
      setHasMore(data.hasNextpage);
      setIsFetching(false);
      setIsLoading(false);
    }, ()=>{
      setIsFetching(false);
    }));
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const [filterMobileShown, setFilterMobileShown] = useState(false);
  
  const handleFilter = (newState) => {
    setCurrentPage(1);
    dispatch(getAllDevices({
      page: 1,
      category: newState.category,
      limit: DEFAULT_LIMIT,
    },(data)=>{
      setDevices(data.devices_list);
      setHasMore(data.hasNextpage);
      setIsFetching(false);
    }, ()=>{
      setIsFetching(false);
    }));
  } 

  const handleChangeOption = (data) => {
    const newState = {...filter,...data}; 
    setFilter(newState)
    handleFilter(newState);
  }

  return (
    <>
      <Header />
      <div className={`browse_games_page browse_base_page page_widget ${filterMobileShown?"filters_shown":""}`}>
        <div className="grid_columns" style={{marginTop: "10px"}}>
          <div className="column filter_column_outer">
            <section className="filter_column">
              <div className="filter_pickers">
                <FilterGroupWidget name="category" items={category} groupName={t("DEVICES-Category")} selectedItem={filter.category} handleChangeOption={handleChangeOption}/>
              </div>
            </section>
          </div>

          <div className="column grid_column_outer" style={{width:"100%"}} onClick={()=>{
            if (filterMobileShown) {
              setFilterMobileShown(false)
            }
          }}>
            <div className="grid_column">
              <div className="browse_header">
                <div className="mobile_filter_row">
                  <button className="mobile_filter_btn" onClick={()=>setFilterMobileShown(true)}>
                    <i className="svgicon fa-solid fa-filter"></i>
                    {t("DETAIL-Filter")}
                  </button>
                </div>
              </div>
              <div className="grid_outer">
                {isLoading && <Loader />}
                <div style={{height: "30px"}}></div>
                <div className="game_grid_widget browse_game_grid animate-bottom" style={{display:`${isLoading?"none":"block"}`}} ref={ref} >
                  {devices?.map((device, index) => (
                    <DeviceCell key={`device-${index}`} device={device} cellSize={cellSize} />
                  ))}
                </div>
              </div>
              {hasMore && (<LoadingMore isLoading={isFetching} />)}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};


const LoadingMore = ({ isLoading }) => (
  <div className="grid_loader">
    <div style={{ display: isLoading ? "flex" : "none" }} className="on_loading">
      <div className="loader_spinner"></div>
      {t("DETAIL-Loading-more")}
    </div>
  </div>
);

const Loader = () => (
  <div className="loader-widget">
     <div className="loader"></div>
  </div>
);


export default Devices;