import { useState } from "react";
import { useDispatch } from "react-redux"
import { deleteAccount } from "../../../slice/userSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DeleteAccount = () => {
    const [data, setDatta] = useState({
        currentPassword: ''
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(deleteAccount(data, (data)=>{
            window.flash(data.message, 'success');
            navigate("/logout");
        },(error)=>{
            window.flash(error.message, 'success');
        }))
    }
    
    const handleChange = (e) => {
        setDatta({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    
    return (
        <>
            <h2>{t("SETTINGS-Delete-title")}</h2>
            <p>
                {t("SETTINGS-Delete-des1")}
            </p>
            <p>
                {t("SETTINGS-Delete-des2")}
            </p>
            <p>{t("SETTINGS-Delete-des3")}</p>
            <form className="form" onSubmit={handleSubmit}>
                <div className="input_row" > 
                    <label>
                        <div className="label">
                            {t("SETTINGS-Delete-password")}
                        </div>
                        <input type="password" value={data.currentPassword} name="currentPassword" onChange={handleChange} />
                    </label>
                </div>
                <div className="buttons">
                    <button className="button">{t("SETTINGS-Delete-button")}</button>
                </div>
            </form>
        </>
    );
}

export default DeleteAccount;