import { useState } from "react";
import { useDispatch } from "react-redux"
import { changePassword } from "../../../slice/userSlice";
import { useTranslation } from "react-i18next"

const Password = () => {
    const [data, setDatta] = useState({
        password:'',
        rePassword:'',
        currentPassword: ''
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changePassword(data, (data)=>{
            window.flash(data.message, 'success')
        },(error)=>{
            window.flash(error.message, 'success')
        }))
    }
    
    const handleChange = (e) => {
        setDatta({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    
    return (
        <>
            <h2>{t("SETTINGS-Password-title")}</h2>
            <form className="form" onSubmit={handleSubmit}>
                <div className="input_row" > 
                    <label>
                        <div className="label">
                            {t("SETTINGS-Password-cur-pass")}
                        </div>
                        <input type="password" value={data.currentPassword} name="currentPassword" onChange={handleChange} />
                    </label>
                </div>
                <div className="input_row" > 
                    <label>
                        <div className="label">
                            {t("SETTINGS-Password-pass")}
                        </div>
                        <input type="password" value={data.password} name="password" onChange={handleChange} />
                    </label>
                </div>
                <div className="input_row" > 
                    <label>
                        <div className="label">
                            {t("SETTINGS-Password-repass")}
                        </div>
                        <input type="password" value={data.rePassword} name="rePassword" onChange={handleChange} />
                    </label>
                </div>
                <div className="buttons">
                    <button className="button">{t("SETTINGS-Password-button")}</button>
                </div>
            </form>
        </>
    );
}

export default Password;