import "./style.css";
import "./ckeditor.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import fullscreenIcon from "../../assets/images/fullscreen.png";
import { useDispatch } from "react-redux";
import { getDetailGame } from "../../slice/gameSlice";
import { checkLoginState } from "../../utils"
import { t } from "i18next";
import useRefDimensions from "../../hooks/useRefDimensions";
import debounce from 'lodash.debounce';
import { isIOS } from "../../utils";

const Detail = () => {
    const { gameId } = useParams();
    const dispatch = useDispatch();
    const [game, setGame] = useState({});

    useEffect(() => {
        dispatch(getDetailGame(gameId, (data) => {
            setGame(data?.game[0]);
        }, () => {
            console.log("get info error");
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function createMarkup() {
        return { __html: game?.description ?? "" };
    }

    return (
        <>
            <Header />
            <div className="main wrapper">
                <div className="inner_column size_large family_aldrich">
                    <div className="view_html_game_page view_game_page page_widget">
                        <div className="ck-content" dangerouslySetInnerHTML={createMarkup()}>
                        </div>
                    </div>
                    {game?.link_game && <GameFrame link_game={game?.link_game} />}
                </div>
            </div>
            <Footer />
        </>
    );
}


function handleCalculateSize(data){
    let expectedWidth = 720;
    let expectedHeight = 576;
    let padding = data?.width > 650? 20: 10;

    if (data.width > expectedWidth) {
        return {
            width: 720,
            height: 576
        }
    }
    else {
        let ratio = (data.width - padding) / expectedWidth;
        return {
            width: expectedWidth*ratio,
            height: expectedHeight*ratio
        }
    }
}

const GameFrame = ({ link_game }) => {
    const [isGamePendding, setIsGamePendding] = useState(true);
    const ref = useRef();
    const [cell, setCell] = useState({
        width: 720,
        height: 576,
    });
    const [styles, setStyles]= useState({});

    const dimensions  = useRefDimensions(ref);

    const handleChangeSize = useRef(debounce(data => {
        // action change size
        setCell(handleCalculateSize(data));
    }, 200)).current;

    useEffect(() => {
        handleChangeSize(dimensions);
    }, [dimensions]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleLoadingGame = () => {
        setIsGamePendding(false);
    }

    const handleEnterFullscreen = () => {
        if (isIOS()){
            setStyles({
                position:"fixed",
                top:0,
                left:0,
                bottom:0,
                right:0,
                width:"100%",
                height:"100%",
                border:"none",
                margin:0,
                padding:0,
                overflow:"hidden",
                zIndex:999999
            });

            return;
        }

        if (document.fullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.msFullscreenEnabled) {

            var iframe = document.querySelector('#game_drop');
            // Do fullscreen
            if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
            } else if (iframe.webkitRequestFullscreen) {
                iframe.webkitRequestFullscreen();
            } else if (iframe.mozRequestFullScreen) {
                iframe.mozRequestFullScreen();
            } else if (iframe.msRequestFullscreen) {
                iframe.msRequestFullscreen();
            }
        }
        else {
            alert(t("GAME-Not-support"));
        }
    }

    return (
        <>
            <div className="html_embed_widget embed_wrapper" ref={ref}>
                <div className={`game_frame ${isGamePendding ? "game_pending" : "game_loaded"}`} style={{ width: cell.width, height: cell.height }}>
                    {isGamePendding ? (
                        <div className="iframe_placeholder">
                            <button className="button load_iframe_btn" onClick={handleLoadingGame}>
                                <i className="svgicon fa-solid fa-play"></i>
                                {checkLoginState() ? t("GAME-Run") : (<Link style={{ textDecoration: "none", color: "#ffffff", textShadow: "0 1px 0px #aa6718" }} to="/login">
                                    {t("GAME-Login")}
                                </Link>)}
                            </button>
                        </div>
                    ) : (
                            <>
                                <button className="fullscreen_btn" onClick={handleEnterFullscreen}>
                                    <img alt="" src={fullscreenIcon} />
                                </button>
                                <iframe style={styles} title="Play game" mozallowfullcreen="true" allow="autoplay; fullscreen *; geolocation; microphone; camera; midi; monetization; xr-spatial-tracking; gamepad; gyroscope; accelerometer; xr" frameBorder="0" src={link_game} msallowfullscreen="true" scrolling="no" allowFullScreen={true} webkitallowfullscreen="true" id="game_drop" allowtransparency="true"></iframe>
                            </>
                        )}

                </div>
            </div>
        </>
    );
}

export default Detail;