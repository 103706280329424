import React, { useState, useRef, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FilterGroupWidget from "../../components/FilterGroupWidget";
import GameCell from "../../components/GameCell";
import { getAllSettings, getAllGames, getAllBanner } from "../../slice/gameSlice";
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce';
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useRefDimensions from "../../hooks/useRefDimensions";
import { handleCalculateSizePerCell} from "../../utils"
import "./style.css";
import 'react-slideshow-image/dist/styles.css'
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { Slide } from 'react-slideshow-image';

const DEFAULT_LIMIT = 20;

const Home = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ref = useRef();
  const dimensions = useRefDimensions(ref);
  const [cellSize, setCellSize] = useState({
    cell:{
      width: "300px",
      height: "250px",
    },
    image:{
      width: "300px",
      height: "250px",
    }
  });

  const [platform, setPlatform] = useState([]);
  const [genre, setGenre] = useState();
  const dispatch = useDispatch();
  const [filter,setFilter] = useState({
    platform:'',
    genre: '',
    sortBy:{ key: 'popular', name:""},
  });


  const fetchMoreListItems = () =>  {
    dispatch(getAllGames({
      page: currentPage + 1,
      sort: filter.sortBy.key,
      platform: filter.platform,
      limit: DEFAULT_LIMIT,
      genre: filter.genre
    },(data)=>{
      setCurrentPage(currentPage+1);
      setGames(prevState => [
        ...prevState,
        ...data.games_list
      ]);
      setHasMore(data.hasNextpage)
      setIsFetching(false);
    }, ()=>{
      setIsFetching(false);
    }));    
  }
  
  const [isFetching, setIsFetching, hasMore, setHasMore] = useInfiniteScroll(fetchMoreListItems);

  const handleChangeSize = useRef(debounce(data => {
    // action change size
    setCellSize(handleCalculateSizePerCell(data));
  }, 200)).current;

  useEffect(()=>{
    handleChangeSize(dimensions);
  },[dimensions]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortItems = [
    {key:'popular', name: t("DETAIL-Popular")},
    {key:'new', name: t("DETAIL-News")},
  ];

  useEffect(()=>{
    setIsLoading(true);
    dispatch(getAllSettings({},(data)=>{
      setGenre(data.genre);
      setPlatform(data.platform);
    },(error)=>{
      console.log(error);
    }))

    dispatch(getAllGames({
      page: currentPage,
      sort: filter.sortBy.key,
      platform: filter.platform,
      limit: DEFAULT_LIMIT,
      genre: filter.genre
    },(data)=>{
      setGames(data.games_list);
      setHasMore(data.hasNextpage);
      setIsFetching(false);
      setIsLoading(false);
    }, ()=>{
      setIsFetching(false);
    }));
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const [filterMobileShown, setFilterMobileShown] = useState(false);
  
  const handleFilter = (newState) => {
    setCurrentPage(1);
    dispatch(getAllGames({
      page: 1,
      sort: newState.sortBy.key,
      platform: newState.platform,
      limit: DEFAULT_LIMIT,
      genre: newState.genre
    },(data)=>{
      setGames(data.games_list);
      setHasMore(data.hasNextpage);
      setIsFetching(false);
    }, ()=>{
      setIsFetching(false);
    }));
    
  } 

  const handleChangeOption = (data) => {
    const newState = {...filter,...data}; 
    setFilter(newState)
    handleFilter(newState);
  }

  return (
    <>
      <Header />
      <div className={`browse_games_page browse_base_page page_widget ${filterMobileShown?"filters_shown":""}`}>
        <Slideshow />
        <div className="grid_columns" style={{marginTop: "10px"}}>
          <div className="column filter_column_outer">
            <section className="filter_column">
              <div className="filter_pickers">
                <FilterGroupWidget name="platform" items={platform} groupName={t("HOME-Platform")} selectedItem={filter.platform} handleChangeOption={handleChangeOption}/>
                <FilterGroupWidget name="genre" items={genre} groupName={t("HOME-Genre")} selectedItem={filter.genre} handleChangeOption={handleChangeOption}/>
              </div>
            </section>
          </div>

          <div className="column grid_column_outer" style={{width:"100%"}} onClick={()=>{
            if (filterMobileShown) {
              setFilterMobileShown(false)
            }
          }}>
            <div className="grid_column">
              <div className="browse_header">
                <div className="mobile_filter_row">
                  <button className="mobile_filter_btn" onClick={()=> setFilterMobileShown(true)}>
                    <i className="svgicon fa-solid fa-filter"></i>
                    {t("DETAIL-Filter")}
                  </button>
                </div>
                <BrowseSortOptionsWidget handleChangeOption={handleChangeOption} sortItems={sortItems} selectedItem={filter.sortBy} />
                <MobileSort selectedItem={filter.sortBy} sortItems={sortItems} handleChangeOption={handleChangeOption} />
              </div>
              <div className="grid_outer">
                {isLoading && <Loader />}
                <div className="game_grid_widget browse_game_grid animate-bottom" style={{display:`${isLoading?"none":"block"}`}} ref={ref} >
                  {games?.map((game, index) => (
                    <GameCell cellSize={cellSize} key={`game-${index}`} game={game} />
                  ))}
                </div>
              </div>
              {hasMore && (<LoadingMore isLoading={isFetching} />)}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const LoadingMore = ({ isLoading }) => (
  <div className="grid_loader">
    <div style={{ display: isLoading ? "flex" : "none" }} className="on_loading">
      <div className="loader_spinner"></div>
      {t("DETAIL-Loading-more")}
    </div>
  </div>
);

const MobileSort = ({ selectedItem , sortItems, handleChangeOption}) => {
  const ref = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isMenuOpen && ref?.current && !ref?.current?.contains(e.target)) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isMenuOpen])

  const handleSelectOption = (data) => {
    setIsMenuOpen(false);
    handleChangeOption({'sortBy':data});
  }

  return (
    <div className="sort_options_mobile" ref={ref}>
      <div className={`filter_picker_widget has_selection ${isMenuOpen?"open popup_visible":""}`}>
        <button className="filter_value" onClick={() => setIsMenuOpen(oldState => !oldState)}>
          <span className="value_label">
            <strong>
              <i className="fa-solid fa-arrow-down-wide-short svgicon icon_sort"></i>
              <span>{t("DETAIL-Sort")}</span>
            </strong>
            {selectedItem?.key === 'popular'?t("DETAIL-Popular"):t("DETAIL-News")}
          </span>
          <i className="fa-solid fa-chevron-down svgicon icon_down_tick2"></i>
        </button>
        <div className="filter_popup">
          <ul className="filter_options">
            {sortItems.filter(item=> item.key !== selectedItem.key).map((item) => (
              <li key={`item-mobile-${item?.key}`} className="filter_option" onClick={() => handleSelectOption(item)}>{item?.name}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const BrowseSortOptionsWidget = ({sortItems, selectedItem, handleChangeOption}) => {
  return (
    <div className="browse_sort_options_widget">
      <span className="sort_label">
        <i className="fa-solid fa-arrow-down-wide-short"></i>
        <div>{t("DETAIL-Sort-by")}</div>
      </span>
      <ul className="sorts">
        { sortItems?.map((item)=>(
            <li onClick={()=>handleChangeOption({'sortBy': item})} key={item?.key}>
              <span className={`${selectedItem.key === item?.key?"active":""}`}>{item?.name}</span>
            </li>
        ))}
      </ul>
    </div>
  );
}


const Loader = () => (
  <div className="loader-widget">
     <div className="loader"></div>
  </div>
);

const Slideshow = () => {
  const dispatch = useDispatch();
  const [slideImages, setSlideImages ] = useState([]);

  useEffect(()=>{
    dispatch(getAllBanner({},(data)=>{
      setSlideImages(data.banner_list);
    }, (error)=>{
      console.error(error);
    }))
  },[])

  return (
    <div>
      <Slide easing="ease">
        {
          slideImages.map((banner)=>(
            <div key={`banner-${banner.uuid}`} className="each-slide">
              <div style={{'backgroundImage': `url(${process.env.REACT_APP_SERVER_URL}/${banner.link_image})`}}>
              </div>
            </div>
          ))
        }
      </Slide>
    </div>
  )
};


export default Home;