import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import GameCell from "../../components/GameCell";
import DeviceCell from "../../components/DeviceCell";
import { getAllSearchResult } from "../../slice/searchSlice";
import { useDispatch } from "react-redux";
import { handleCalculateSizePerCell } from "../../utils"
import "./style.css";
import { t } from 'i18next';
import debounce from 'lodash.debounce';
import useRefDimensions from "../../hooks/useRefDimensions";


const Search = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef();
    const [searchParams] = useSearchParams();
    const keyword = searchParams.get('q');
    const { t } = useTranslation();
    const [result, setResult] = useState([]);
    const dimensions = useRefDimensions(ref);
    const [cellSize, setCellSize] = useState({
        cell: {
            width: "300px",
            height: "250px",
        },
        image: {
            width: "300px",
            height: "250px",
        }
    });


    const handleChangeSize = useRef(debounce(data => {
        // action change size
        setCellSize(handleCalculateSizePerCell(data));
    }, 200)).current;

    useEffect(() => {
        setIsLoading(true);
        dispatch(getAllSearchResult({
            q: keyword,
        }, (data) => {
            setResult(data.data);
            window.dispatchEvent(new Event('resize'));
            setIsLoading(false);
        }, () => { }));
    }, [keyword]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleChangeSize(dimensions);
    }, [dimensions]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header />
            <div className="search_page grid_results_page page_widget">
                <div className="search_header grid_header">
                    <h2>
                        {t("SEARCH-Tittle")}'{keyword}'
                    </h2>
                    <div className="grid_outer">
                        {isLoading && <Loader />}
                        <div className="animate-bottom">
                            {
                                result?.length > 0 ? <ResultGridWidget refWidget={ref} cellSize={cellSize} datas={result} /> : <NoResult />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}


const NoResult = () => (
    <div className="empty_message">
        <i className="icon fa-regular fa-face-meh"></i>
        {t("SEARCH-NoResult")}
        <br />
    </div>
);

const ResultGridWidget = ({ datas, cellSize, refWidget }) => (
    <div style={{ paddingLeft: 10, paddingRight: 0 }} className="game_grid_widget browse_game_grid" ref={refWidget}>
        {datas?.map((data, index) => {
            if (data.genres) return (<GameCell key={`search-result-${index}`} game={data} cellSize={cellSize} />)
            return (<DeviceCell key={`search-result-${index}`} device={data} cellSize={cellSize} />)
        }
        )}
    </div>
);

const Loader = () => (
    <div className="loader-widget">
        <div className="loader"></div>
    </div>
);

export default Search;