import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getDetailDevice } from "../../slice/deviceSlice";


const DetailDevice = () => {
    const { deviceId } = useParams();
    const dispatch = useDispatch();
    const [device, setDevice] = useState({});

    useEffect(() => {
        dispatch(getDetailDevice(deviceId, (data) => {
            setDevice(data?.devices[0]);
        }, () => {
            console.log("get info error");
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function createMarkup() {
        return { __html: device?.description ?? "" };
    }

    return (
        <>
            <Header />
            <div className="main wrapper">
                <div className="inner_column size_large family_aldrich">
                    <div className="view_html_game_page view_game_page page_widget">
                        <div className="ck-content" dangerouslySetInnerHTML={createMarkup()}>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default DetailDevice;