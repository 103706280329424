
import React, { useEffect, useState } from 'react';
import Bus from '../../utils/Bus';

import './style.css';

export const Flash = () => {
    let [visibility, setVisibility] = useState(false);
    let [message, setMessage] = useState('');
    let [type, setType] = useState('');

    useEffect(() => {
        Bus.addListener('flash', ({message, type}) => {
            setVisibility(true);
            setMessage(message);
            setType(type);
            setTimeout(() => {
                setVisibility(false);
            }, 4000);
        });
                
        return ()=> Bus.removerListener('flash');
    }, []);

    useEffect(() => {
        if(document.querySelector('.close') !== null) {
            document.querySelector('.close').addEventListener('click', () => setVisibility(false));
        }
    })

    return (
        visibility && (
            <div className={`global_flash notice animated flash-${type}`} role="alert" aria-live="polite" style={{marginLeft: "-60.6875px", marginTop: "-7px"}}>{message}</div>
        )
    )
}

export default Flash;