import apiCaller from "./config";

export const getDetailDevice = async (deviceId) => {
    const path = `/devices/${deviceId}`;
    const result = await apiCaller("GET", path, {} );
    return result;
}

export const getAllDevices = async (data) => {
    const path = "/devices";
    const result = await apiCaller("GET", path,null, data);
    return result;
}

export const getAllCategory = async (data) => {
    const path = "/settings/category";
    const result = await apiCaller("GET", path,null, {});
    return result;
}
