import { NavLink } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./style.css";

const Settings = () => {
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <div className="main">
                <div className="inner_column">
                    <div className="user_settings_profile_page user_settings_page_page page_widget">
                        <div className="stat_header_widget">
                            <div className="text_container">
                                <h2>{t("SETTINGS-Settings")}</h2>
                            </div>
                        </div>
                        <div className="tab_columns">
                            <div className="tab_column">
                                <NavLink to="/settings"
                                    className={(navData) => (navData.isActive && window.location.pathname==="/settings" ? 'tab_btn selected' : 'tab_btn')}
                                >
                                    {t("SETTINGS-Profile")}
                                </NavLink>
                                <NavLink to="/settings/password" 
                                    className={(navData) => (navData.isActive ? 'tab_btn selected' : 'tab_btn')}
                                >
                                    {t("SETTINGS-Password")}
                                </NavLink>
                                <NavLink to="/settings/email-addresses" 
                                    className={(navData) => (navData.isActive ? 'tab_btn selected' : 'tab_btn')} 
                                    >
                                    {t("SETTINGS-Email")}
                                </NavLink>
                                <NavLink to="/settings/delete-account" 
                                    className={(navData) => (navData.isActive ? 'tab_btn selected' : 'tab_btn')} 
                                    >
                                    {t("SETTINGS-Delete")}
                                </NavLink>
                            </div>
                            <div className="content_column">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Settings;