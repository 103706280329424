import { getDetailGame as getDetailGameApi,
    getAllSettings as getAllSettingsApi,
    getAllGames as getAllGamesApi,
    getAllBanner as getAllBannerApi,
    getAllBannerForMobileView as getAllBannerForMobileViewApi 
 } from "../apis/game";
import { isMobileView } from "../utils";

export const getDetailGame =
    (data, resolve, reject) => async () => {
        try {
            const res = await getDetailGameApi(data);
            if (res.success) {
                resolve(res.data);
            } else {
                reject(res);
            }
        } catch (error) {
            reject(error);
        }
    };

export const getAllSettings =
    (data, resolve, reject) => async () => {
        try {
            const res = await getAllSettingsApi(data);
            if (res.success) {
                resolve(res.data);
            } else {
                reject(res.message);
            }
        } catch (error) {
            reject(error);
        }
    };

export const getAllGames =
    (data, resolve, reject) => async () => {
        try {
            const res = await getAllGamesApi(data);
            if (res.success) {
                resolve(res.data);
            } else {
                reject(res.message);
            }
        } catch (error) {
            reject(error);
        }
    };

export const getAllBanner =
(data, resolve, reject) => async () => {
    try {
        let res;
        if (isMobileView()){
            res = await getAllBannerForMobileViewApi(data);
        }
        else{
            res = await getAllBannerApi(data);
        }

        if (res.success) {
            resolve(res.data);
        } else {
            reject(res.message);
        }
    } catch (error) {
        reject(error);
    }
};
