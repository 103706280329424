import { useState, useEffect } from "react";

const useRefDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({ width: 1, height: 2 })
    useEffect(() => {
      const handleChange = () => {
        if (ref.current) {
          const { current } = ref;
          const boundingRect = current.getBoundingClientRect()
          const { width, height } = boundingRect
          setDimensions({ width: Math.round(width), height: Math.round(height) })
        }
      }
  
      window.addEventListener('resize', handleChange);
      handleChange();
      return () => window.removeEventListener('resize', handleChange);
    }, [ref])
    return dimensions
  }

export default useRefDimensions;