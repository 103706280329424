import { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LanguageWidget from "../LanguageWidget";
import { useTranslation } from "react-i18next";

import "./style.css";

const Header = () => {
    const [keyword,setKeyword] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/search?q=${keyword}`);
    }

    const handleChange = (e) => {
        setKeyword(e.target.value);
    }

    return (
        <>
            <div className="header_widget header_widget minimal_header" >
                <div className="primary_header">
                    <h1 className="title">
                        <Link className="header_logo" to="/" />
                    </h1>
                    <div className="header_buttons">
                        <NavLink to="/" className={(navData) => (navData.isActive ? 'header_button active' : 'header_button')}>{t("HEADER-App")}</NavLink>
                        <NavLink to="/devices" className={(navData) => (navData.isActive ? 'header_button active' : 'header_button')}>{t("HEADER-Device")}</NavLink>
                    </div>
                    <form className="game_search" onSubmit={handleSubmit}>
                        <input value={keyword} className="search_input" required name="q" placeholder={t("HEADER-Search_placeholder")} type="text" onChange={handleChange}/>
                        <button className="submit_btn">
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </form>
                    <UserPanelWidget />
                    <LanguageWidget />
                </div>
            </div>
            <SearchMobile keyword={keyword} handleChange={handleChange} handleSubmit={handleSubmit}/>
            {(window.location.pathname==="/" || window.location.pathname==="/devices") && (
                <div className="header_mobile_buttons">
                <NavLink to="/" className={(navData) => (navData.isActive ? 'header_mobile_button active' : 'header_mobile_button')}>{t("HEADER-App")}</NavLink>
                <NavLink to="/devices" className={(navData) => (navData.isActive ? 'header_mobile_button active' : 'header_mobile_button')}>{t("HEADER-Device")}</NavLink>
            </div>
            ) }
        </>
    );
}

const UserPanelWidget = () => {
    const user = useSelector((state) => state.userAuth.user);
    const ref_userpanel = useRef()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isMenuOpen && ref_userpanel?.current && !ref_userpanel?.current?.contains(e.target)) {
                setIsMenuOpen(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isMenuOpen])

    return (
        <div className="user_panel_widget logged_in">
            {
                !user ? (
                    <>
                        <Link className="panel_button" to="/login">{t('HEADER-Login')}</Link>
                        <Link className="panel_button register_button" to="/register">{t('HEADER-Register')}</Link>
                    </>
                ) : (
                        <>
                            <Link to="#" className="profile_link">
                                <img alt="" src="https://itch.io/static/images/frog-cyan.png" className="profile_avatar" />
                                <span className="user_name">{user?.fullname}</span>
                            </Link>
                            <div className={`drop_menu_wrap ${isMenuOpen ? "open" : ""}`}>
                                <button type='button' className="menu_tick" onClick={() => setIsMenuOpen(true)}>
                                    <i className="fa-solid fa-chevron-down"></i>
                                </button>
                                <div className="drop_menu" ref={ref_userpanel}>
                                    <div className="menu_group">
                                        <div className="group_header">
                                            {t("HEADER-Account")}
                                    </div>
                                        <Link to="/settings" className="link_row">{t("HEADER-Settings")}</Link>
                                        <Link to="/logout" className="link_row">{t("HEADER-Logout")}</Link>
                                    </div>
                                </div>
                            </div>

                        </>
                    )
            }
        </div>
    );
}

const SearchMobile = ({ keyword, handleChange, handleSubmit  }) => {
    const { t } = useTranslation();

    return (
        <form className="game_search_mobile" onSubmit={handleSubmit}>
            <input value={keyword} className="search_input" required name="q" placeholder={t("HEADER-Search_placeholder")} type="text" onChange={handleChange}/>
            <button className="submit_btn">
                <i className="fa-solid fa-magnifying-glass"></i>
            </button>
        </form>
    );
}

export default Header;