import { configureStore } from "@reduxjs/toolkit";
import userAuthReducer from "./slice/userAuthSlice";

const store = configureStore({
    reducer: {
        userAuth: userAuthReducer,
    },
});

export const { getState, dispatch } = store;

export default store;