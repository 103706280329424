import { useState, useEffect } from "react";

const useInfiniteScroll = callback => {
    const [isFetching, setIsFetching] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    },[]);

    useEffect(() => {
        if (!isFetching) return;
        if (hasMore) {
            callback(() => {});
        }
    }, [isFetching, hasMore]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleScroll() {
        if (
            window.innerHeight + document.documentElement.scrollTop !==
            document.documentElement.offsetHeight
        )
            return;
        setIsFetching(true);
    }

    return [isFetching, setIsFetching, hasMore, setHasMore];
};

export default useInfiniteScroll;
