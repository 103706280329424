import React from "react";
import notFound from "../../assets/images/notfound.png";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

import './style.css';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="main">
        <div className="inner_column">
          <div className="">
            <div className="not_found_page page_widget">
              <div className="padded">
                <div className="image_wrapper">
                  <img alt="Page not found" width={300} src={notFound} />
                </div>
                <div className="page_message">
                  <h2>{t("404PAGE-Text")}</h2>
                  <div className="bottom_links">
                    <Link to="/">{t("404PAGE-Back")}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;